import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import { MDXRenderer } from "gatsby-plugin-mdx"
import "katex/dist/katex.min.css"
import "../components/Complete/Headings"

const MdxBlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  // const siteTitle = data.site.siteMetadata.title
  // const { previous, next } = pageContext

  return (
    <Layout
      location={location}
      title="Home"
      showSidebar={false}
      currPage={"Post"}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1
            style={{
              //fontFamily: "Montserrat, sans-serif",
              fontFamily: "system-ui",
              fontWeight: "900",
              fontSize: "2.25rem",
              marginTop: "50px",
              color: "#000000",
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
              color: "#656565",
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        {/* <section dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          {/* <li>
            {previous && (
              <Link to={previous.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li> */}
        </ul>
      </nav>
    </Layout>
  )
}

export default MdxBlogPostTemplate

export const pageQuery = graphql`
  query MdxBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { eq: $slug }) {
      body
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
        description
      }
      slug
    }
  }
`
